import _ from "lodash";
import axios from "axios";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { API_ROUTE_FULL_PATHS } from "../constants/routes/apiPath";
import { CategoryType } from "../constants/ga";
import toastIds from "../constants/toastIds";
import i18n from "../../i18n";

const RESPONSE_MSG = "response.data.msg";

const handleRequestErr = (err: any, displayMessage: boolean = true) => {
  const STATUS_400 = 400;
  const STATUS_401 = 401;
  const STATUS_403 = 403;
  const STATUS_500 = 500;
  const STATUS_411 = 411;
  const STATUS_409 = 409;
  const STATUS_410 = 410;
  const STATUS_429 = 429;
  const NUM_2 = 2;

  if (axios.isAxiosError(err)) {
    if (err.response?.status === STATUS_401) {
      if (displayMessage) {
        const errMsg = _.get(err, RESPONSE_MSG, null);
        if (typeof errMsg === "string") {
          toast.error(i18n.t(errMsg) as string, {
            toastId: toastIds.UNAUTHORIZED,
          });
        } else if (err.config.url !== API_ROUTE_FULL_PATHS.userGetUserData) {
          toast.error(i18n.t("message.toast.unauthorized") as string, {
            toastId: toastIds.UNAUTHORIZED2,
          });
        }
      }
    } else if (err.code === "ERR_NETWORK") {
      toast.error(err.message, {
        toastId: toastIds.NETWORK_ERROR,
      });
    } else if (err.response?.status === STATUS_400) {
      const errMsg: any = _.get(err, "response.data", null);
      if (Array.isArray(errMsg)) {
        for (let i = 0; i < Math.min(errMsg.length, NUM_2); i++) {
          toast.error(errMsg[i]?.msg ?? "", {});
        }
      } else {
        toast.error(i18n.t("message.toast.badRequest") as string, {
          toastId: toastIds.BADREQUEST,
        });
      }
    } else if (err.response?.status === STATUS_403) {
      if (displayMessage) {
        const errMsg = _.get(err, RESPONSE_MSG, null);
        if (typeof errMsg === "string") {
          toast.error(i18n.t(errMsg) as string, {
            toastId: toastIds.FORBIDDEN,
          });
        } else {
          toast.error(i18n.t("message.toast.forbidden") as string, {
            toastId: toastIds.FORBIDDEN2,
          });
        }
      }
    } else if (err.response?.status === STATUS_500) {
      toast.error(i18n.t("message.toast.serverError") as string, {
        toastId: toastIds.INTERNAL_SERVER_ERROR,
      });
    } else if (err.response?.status === STATUS_411) {
      toast.error(i18n.t("message.toast.notAllowed") as string, {
        toastId: toastIds.NOTALLOWED,
      });
    } else if (err.response?.status === STATUS_409) {
      if (displayMessage) {
        const errMsg = _.get(err, RESPONSE_MSG, null);
        if (typeof errMsg === "string") {
          toast.error(i18n.t(errMsg) as string, {
            toastId: toastIds.CONFLICTS,
          });
        } else {
          toast.error(i18n.t("message.toast.conflicts") as string, {
            toastId: toastIds.CONFLICTS2,
          });
        }
      }
    } else if (err.response?.status === STATUS_410) {
      if (displayMessage) {
        const errMsg = _.get(err, RESPONSE_MSG, null);
        if (typeof errMsg === "string") {
          toast.error(i18n.t(errMsg) as string, {
            toastId: toastIds.GONE,
          });
        } else {
          toast.error(i18n.t("message.toast.gone") as string, {
            toastId: toastIds.GONE2,
          });
        }
      }
    } else if (err.response?.status === STATUS_429) {
      const errMsg = _.get(err, RESPONSE_MSG, null);
      if (typeof errMsg === "string") {
        toast.error(i18n.t(errMsg) as string, {
          toastId: toastIds.TOOMANYREQ,
        });
      } else {
        toast.error(i18n.t("message.toast.tooManyReq") as string, {
          toastId: toastIds.TOOMANYREQ2,
        });
      }
    } else {
      const errMsg = _.get(err, "response.data", null);
      if (typeof errMsg === "string") {
        toast.error(i18n.t(errMsg) as string, {
          toastId: toastIds.UNDEFINED,
        });
      }
    }

    if (err.response?.status === STATUS_500 || err.code === "ERR_NETWORK") {
      ReactGA.event({
        category: CategoryType.AxiosError,
        action: err.config.url ?? "",
        label: `${err.name} - ${err.message}`,
        value: STATUS_500,
        nonInteraction: true,
      });
    } else {
      ReactGA.event({
        category: CategoryType.AxiosWithRejectMessage,
        action: err.config.url ?? "",
        label: `${err.name} - ${err.message}`,
        value: err.response?.status ?? 0,
        nonInteraction: true,
      });
    }

    return {
      success: false,
      status: err.response?.status === STATUS_401 ? STATUS_401 : undefined,
    };
  }

  ReactGA.event({
    category: CategoryType.UnClassifedError,
    action: CategoryType.UnClassifedError,
    label: JSON.stringify(err),
    nonInteraction: true,
  });
  toast.error(i18n.t("message.toast.unexpectedError") as string, {
    toastId: toastIds.UNEXPECTED_ERROR,
  });
  return {
    success: false,
  };
};

export default handleRequestErr;
