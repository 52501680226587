import * as yup from "yup";
const HK_MACU_MOBILE_LOCAL_REGEX = /^[2-9][0-9]{7}$/;
const NUM_8 = 8;
export const mobileLocalYup = yup
  .string()
  .min(NUM_8, "required min input 8 characters, e.g. 23456789")
  .test("test-mobile", "required Hong Kong / Macau mobile format", (val) => {
    if (val === undefined) return false;
    return HK_MACU_MOBILE_LOCAL_REGEX.test(val);
  })
  .required("required");

export const mobileNotRequiredYup = yup
  .string()
  .test("empty", "required min input 8 characters, e.g. 23456789", (val) => {
    if (val === undefined) return false;
    return val.length === 0 || val.length >= 8;
  })
  .test("test-mobile", "required Hong Kong / Macau mobile format", (val) => {
    if (val === undefined) return false;
    return val.length === 0 ? true : HK_MACU_MOBILE_LOCAL_REGEX.test(val);
  });
